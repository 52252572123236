import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "lg:flex justify-between mt-3" }
const _hoisted_2 = { class: "text-sm mt-3" }
const _hoisted_3 = { class: "text-sm mt-3" }
const _hoisted_4 = { class: "text-sm mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_input_default = _resolveComponent("input-default")!
  const _component_centered_modal = _resolveComponent("centered-modal")!

  return (_ctx.open)
    ? (_openBlock(), _createBlock(_component_centered_modal, {
        key: 0,
        onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('close'))),
        title: _ctx.$t('change_password'),
        cancelButtonText: _ctx.$t('cancel'),
        mainContainerId: 'settings_container'
      }, {
        actionButton: _withCtx(() => [
          _createVNode(_component_custom_button, {
            id: 'save-button',
            class: "max-w-full",
            isDisabled: !_ctx.isAllFilled,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updatePassword()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("save")), 1)
            ]),
            _: 1
          }, 8, ["isDisabled"])
        ]),
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_input_default, {
              id: 'current_password',
              name: 'current_password',
              modelValue: _ctx.oldPassword,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.oldPassword) = $event)),
              type: 'password',
              label: 'Aktuelles Passwort',
              class: "lg:w-64 w-full lg:mb-0 mb-3"
            }, null, 8, ["modelValue"]),
            _createVNode(_component_input_default, {
              id: 'password',
              name: 'password',
              modelValue: _ctx.newPassword,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newPassword) = $event)),
              type: 'password',
              label: 'Neues Passwort',
              class: "lg:w-64 w-full lg:mb-0 mb-3"
            }, null, 8, ["modelValue"]),
            _createVNode(_component_input_default, {
              id: 'repeatPassword',
              name: 'repeatPassword',
              modelValue: _ctx.repeatNewPassword,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.repeatNewPassword) = $event)),
              type: 'password',
              label: 'Passwort wiederholen',
              class: "lg:w-64 w-full"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("settings.change_password_text1")), 1),
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("settings.change_password_text2")), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("settings.change_password_text3")), 1)
        ]),
        _: 1
      }, 8, ["title", "cancelButtonText"]))
    : _createCommentVNode("", true)
}