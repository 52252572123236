import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-2 pl-8 w-full overflow-y-auto"
}
const _hoisted_2 = { class: "mt-6" }
const _hoisted_3 = { class: "flex justify-between mb-6" }
const _hoisted_4 = { class: "border-2 border-transparent hover:border-primary flex justify-center items-center p-2 rounded-md" }
const _hoisted_5 = {
  key: 0,
  class: "md:flex justify-between"
}
const _hoisted_6 = { class: "text-grey-darkest" }
const _hoisted_7 = { class: "md:w-72 w-full md:mt-0 mt-3" }
const _hoisted_8 = {
  key: 1,
  class: "md:flex justify-between"
}
const _hoisted_9 = { class: "text-grey-darkest" }
const _hoisted_10 = { class: "md:w-72 w-full md:mt-0 mt-3" }
const _hoisted_11 = {
  key: 2,
  class: "md:flex justify-between"
}
const _hoisted_12 = { class: "text-grey-darkest" }
const _hoisted_13 = { class: "md:w-72 w-full md:mt-0 mt-3" }
const _hoisted_14 = { class: "flex justify-between" }
const _hoisted_15 = { class: "mt-2 text-grey-darkest" }
const _hoisted_16 = { class: "flex justify-between mt-6 mb-6" }
const _hoisted_17 = { class: "mt-2 text-grey-darkest" }
const _hoisted_18 = { class: "flex justify-between" }
const _hoisted_19 = { class: "mt-2 text-grey-darkest" }
const _hoisted_20 = { class: "text-xs mt-2 text-grey-dark" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_avatar_container = _resolveComponent("avatar-container")!
  const _component_input_default = _resolveComponent("input-default")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_input_switch = _resolveComponent("input-switch")!
  const _component_image_upload_modal = _resolveComponent("image-upload-modal")!
  const _component_change_email_modal = _resolveComponent("change-email-modal")!
  const _component_change_password_modal = _resolveComponent("change-password-modal")!

  return (_ctx.user)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h6", null, _toDisplayString(_ctx.$t("profil_and_security")), 1),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_avatar_container, {
              id: 'profil-avatar',
              avatarStyle: {},
              name: _ctx.displayName,
              src: _ctx.user.avatar_url ? _ctx.user.avatar_url : '',
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openImageUpload = true))
            }, null, 8, ["name", "src"]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", {
                class: "text text-primary cursor-pointer",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openImageUpload = true))
              }, _toDisplayString(_ctx.$t("settings.upload_image")), 1)
            ])
          ]),
          (!_ctx.user.customer)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("first_name")), 1),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_input_default, {
                    id: 'first_name',
                    name: 'first_name',
                    modelValue: _ctx.user.first_name,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.user.first_name) = $event)),
                    type: 'text',
                    label: _ctx.$t('first_name'),
                    onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateUserData('first_name')))
                  }, null, 8, ["modelValue", "label"])
                ])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.user.customer)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t("last_name")), 1),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_input_default, {
                    id: 'last_name',
                    name: 'last_name',
                    modelValue: _ctx.user.last_name,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.user.last_name) = $event)),
                    type: 'text',
                    label: _ctx.$t('last_name'),
                    onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.updateUserData('last_name')))
                  }, null, 8, ["modelValue", "label"])
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.user.customer)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t("name")), 1),
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_input_default, {
                    id: 'customer_name',
                    name: 'customer_name',
                    modelValue: _ctx.user.customer.name,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.user.customer.name) = $event)),
                    type: 'text',
                    label: _ctx.$t('name'),
                    isDisabled: true
                  }, null, 8, ["modelValue", "label"])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.$t("settings.email")), 1),
            _createVNode(_component_custom_button, {
              class: "md:mt-0 mt-3",
              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.changeEmail = true))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("settings.change_email")), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.$t("settings.password")), 1),
            _createVNode(_component_custom_button, {
              class: "md:mt-0 mt-3",
              onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.changePassword = true))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("settings.change_password")), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", null, [
              _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.$t("settings.two_factor_auth")), 1),
              _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.$t("settings.two_factor_text")), 1)
            ]),
            _createVNode(_component_input_switch, {
              class: "md:mt-0 mt-3",
              id: 'two-factor-switch',
              name: 'two-factor-switch',
              modelValue: _ctx.user.has_two_factor_enabled,
              "onUpdate:modelValue": [
                _cache[9] || (_cache[9] = ($event: any) => ((_ctx.user.has_two_factor_enabled) = $event)),
                _cache[10] || (_cache[10] = ($event: any) => (_ctx.updateUserData('twoFactor')))
              ],
              label: '',
              tabIndex: 1
            }, null, 8, ["modelValue"])
          ])
        ]),
        (_ctx.openImageUpload)
          ? (_openBlock(), _createBlock(_component_image_upload_modal, {
              key: 0,
              open: _ctx.openImageUpload,
              onClose: _cache[11] || (_cache[11] = ($event: any) => (_ctx.openImageUpload = false)),
              onSaveImage: _ctx.saveImage
            }, null, 8, ["open", "onSaveImage"]))
          : _createCommentVNode("", true),
        _createVNode(_component_change_email_modal, {
          open: _ctx.changeEmail,
          onClose: _cache[12] || (_cache[12] = ($event: any) => (_ctx.changeEmail = false))
        }, null, 8, ["open"]),
        _createVNode(_component_change_password_modal, {
          open: _ctx.changePassword,
          onClose: _cache[13] || (_cache[13] = ($event: any) => (_ctx.changePassword = false))
        }, null, 8, ["open"])
      ]))
    : _createCommentVNode("", true)
}