import { FileUploadStyleInterface } from "./file_upload_interface";

export const fileUploadDefaultStyle: FileUploadStyleInterface = {
    height: "44",
    width: "44",
    borderColor: "grey",
    iconColor: "grey",
    hoverHeight: "44",
    hoverWidth: "44",
    hoverBorderColor: "primary",
    hoverIconColor: "primary",
};
