
import { computed, defineComponent, reactive, ref } from "vue";
import CenteredModal from "../../../../components/layout/modals/CenteredModal.vue";
import CustomButton from "../../../../components/inputs/customButton/CustomButton.vue";
import InputDefault from "../../../../components/inputs/inputDefault/InputDefault.vue";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/store/auth";
import { useHelpersStore } from "@/store/helpers";
import { ToastMessageType } from "@/components/helpers/theToastMessage/toast_message_helpers";

export default defineComponent({
    name: "settingsChangeEmailModal",
    components: { CenteredModal, CustomButton, InputDefault },
    props: {
        open: {
            type: Boolean,
            required: true,
        },
    },
    emits: ["close", "saveImage"],
    setup(props, ctx) {
        const { updateUser } = useAuthStore();
        const { user } = storeToRefs(useAuthStore());
        const { setLoadingId, removeLoadingId } = useHelpersStore();
        const { toastMessageText } = storeToRefs(useHelpersStore());
        const email = ref<string>("");
        const repeatEmail = ref<string>("");
        const state = reactive({});
        const isAllFilled = computed(() => {
            let isFilled = false;
            if (email.value && repeatEmail.value) {
                isFilled = true;
            }
            return isFilled;
        });

        async function saveNewEmail(): Promise<void> {
            setLoadingId("save-button");
            const response = await updateUser({
                email: email.value,
                email_confirmation: repeatEmail.value,
                has_two_factor_enabled: user.value?.has_two_factor_enabled
                    ? user.value.has_two_factor_enabled
                    : false,
            });
            removeLoadingId("save-button");
            if (response?.status == 200) {
                toastMessageText.value = [
                    {
                        type: "success" as ToastMessageType,
                        message: "E-Mail wurde erfolgreich aktualisiert.",
                    },
                ];
                ctx.emit("close");
            }
        }
        return { state, isAllFilled, email, repeatEmail, user, saveNewEmail };
    },
});
