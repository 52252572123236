
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import MiniModal from "../../../../components/layout/modals/MiniModal.vue";
import FileUpload from "../../../../components/helpers/fileUpload/FileUpload.vue";
import ImageCropper from "../../../../components/helpers/imageCropper/ImageCropper.vue";
import CustomButton from "../../../../components/inputs/customButton/CustomButton.vue";

export default defineComponent({
    name: "profileImageUploadModal",
    components: { MiniModal, FileUpload, CustomButton, ImageCropper },
    props: {
        open: {
            type: Boolean,
            required: false,
        },
        mainContainerId: {
            type: String,
            default: "main_container",
        },
    },
    emits: ["close", "saveImage"],
    setup(props, ctx) {
        const state = reactive({});
        const fileInput = ref();

        const file = ref<any>({});
        const computedfile = computed(() => {
            let compfile = file.value;

            const array: File[] = [];
            if (compfile != null) {
                if (compfile.length >= 1) {
                    for (let index = 0; index < compfile.length; index++) {
                        const element = compfile[index];
                        array.push(element);
                    }
                }
            }
            return array;
        });
        function saveImage(image: any) {
            ctx.emit("saveImage", image.croppedImage);
        }

        function createURLsingle(file: File) {
            if (file != null) {
                return URL.createObjectURL(file);
            }
            return "";
        }

        function inputChangeEvent(event: Event): void {
            const target = event.target as HTMLInputElement;
            const files: FileList | null = target.files;
            if (files) {
                file.value = files[0];
            }
        }

        onMounted(() => {
            file.value = null;
        });
        return {
            state,
            computedfile,
            file,
            createURLsingle,
            saveImage,
            inputChangeEvent,
            fileInput,
        };
    },
});
