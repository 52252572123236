import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-sm mt-3" }
const _hoisted_2 = { class: "lg:flex justify-between mt-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_input_default = _resolveComponent("input-default")!
  const _component_centered_modal = _resolveComponent("centered-modal")!

  return (_ctx.open)
    ? (_openBlock(), _createBlock(_component_centered_modal, {
        key: 0,
        onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('close'))),
        title: 'E-Mail ändern',
        cancelButtonText: _ctx.$t('cancel'),
        mainContainerId: 'settings_container'
      }, {
        actionButton: _withCtx(() => [
          _createVNode(_component_custom_button, {
            id: 'save-button',
            class: "max-w-full",
            isDisabled: !_ctx.isAllFilled,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.saveNewEmail()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("save")), 1)
            ]),
            _: 1
          }, 8, ["isDisabled"])
        ]),
        content: _withCtx(() => [
          _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t("settings.change_email_text1")) + " " + _toDisplayString(_ctx.user?.email ?? "-") + ". " + _toDisplayString(_ctx.$t("settings.change_email_text2")), 1),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_input_default, {
              id: 'email',
              name: 'email',
              modelValue: _ctx.email,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email) = $event)),
              type: 'text',
              label: 'Neue E-Mail',
              class: "lg:w-96 w-full lg:mb-0 mb-3"
            }, null, 8, ["modelValue"]),
            _createVNode(_component_input_default, {
              id: 'repeat-new-email',
              name: 'repeat-new-email',
              modelValue: _ctx.repeatEmail,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.repeatEmail) = $event)),
              type: 'text',
              label: 'E-Mail wiederholen',
              class: "lg:w-96 w-full"
            }, null, 8, ["modelValue"])
          ])
        ]),
        _: 1
      }, 8, ["cancelButtonText"]))
    : _createCommentVNode("", true)
}