import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full h-full" }
const _hoisted_2 = { class: "w-full text-center font-medium text-xl mb-2" }
const _hoisted_3 = { class: "w-full text-center font-medium text-sm mb-3" }
const _hoisted_4 = {
  key: 1,
  class: "flex md:pl-18 md:pr-18 justify-between mt-7"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_file_upload = _resolveComponent("file-upload")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_image_cropper = _resolveComponent("image-cropper")!
  const _component_mini_modal = _resolveComponent("mini-modal")!

  return (_openBlock(), _createBlock(_component_mini_modal, {
    onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('close'), (_ctx.file = null))),
    isCentered: false,
    mainContainerId: _ctx.mainContainerId
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.computedfile[0] == null
                        ? _ctx.$t("settings.upload_image")
                        : _ctx.$t("settings.edit_image")), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.computedfile[0] == null
                        ? _ctx.$t("settings.upload_image_text")
                        : _ctx.$t("settings.edit_image_text")), 1),
        (_ctx.computedfile[0] == null)
          ? (_openBlock(), _createBlock(_component_file_upload, {
              key: 0,
              id: 'fileUpload',
              name: 'fileUpload',
              dragId: 'fileUpload',
              multiple: false,
              acceptedTypes: 'image/*',
              fileUploadStyle: {},
              modelValue: _ctx.file,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.file) = $event)),
              class: "mt-7"
            }, null, 8, ["modelValue"]))
          : _createCommentVNode("", true),
        (_ctx.computedfile[0] == null)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_custom_button, {
                isPlainButton: true,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close'), (_ctx.file = null)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("cancel")), 1)
                ]),
                _: 1
              }),
              _createElementVNode("input", {
                id: "file",
                type: "file",
                ref: "fileInput",
                accept: 'image/*',
                class: "hidden",
                onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.inputChangeEvent && _ctx.inputChangeEvent(...args)))
              }, null, 544),
              _createVNode(_component_custom_button, {
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.fileInput.click()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("settings.upload_picture")), 1)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (
                    _ctx.computedfile[0] != null && _ctx.createURLsingle(_ctx.computedfile[0])
                )
          ? (_openBlock(), _createBlock(_component_image_cropper, {
              key: 2,
              id: 'image-cropper',
              class: "w-full",
              name: 'image-cropper',
              file: _ctx.createURLsingle(_ctx.computedfile[0]),
              maxZoom: 4,
              onSaveImage: _ctx.saveImage,
              onRemoveImage: _cache[4] || (_cache[4] = ($event: any) => (_ctx.file = null))
            }, {
              declineButton: _withCtx(({ removeImage }) => [
                _createVNode(_component_custom_button, {
                  class: "mt-5 w-44 ml-10",
                  id: 'cancel-upload-button',
                  isPlainButton: true,
                  onClick: removeImage
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("cancel")), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]),
              submitButton: _withCtx(({ saveImage }) => [
                _createVNode(_component_custom_button, {
                  class: "mt-5 w-44 mr-10",
                  id: 'save-upload-button',
                  onClick: saveImage
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("save")), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]),
              _: 1
            }, 8, ["file", "onSaveImage"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["mainContainerId"]))
}