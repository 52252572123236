
import { defineComponent, ref } from "vue";
import Cropper from "cropperjs";
import CustomButton from "@/components/inputs/customButton/CustomButton.vue";
import { CustomButtonStyleInterface } from "@/components/inputs/customButton/custom_button_interfaces";
import IconMinus from "@/components/icons/IconMinus.vue";
import IconPlus from "@/components/icons/IconPlus.vue";

export default defineComponent({
    name: "ImageCropper",
    components: { CustomButton, IconMinus, IconPlus },
    emits: ["saveImage", "removeImage"],
    props: {
        /**
         * id is necessary to uniquely identify this cropper
         */
        id: {
            type: String,
            required: true,
        },
        /**
         * specifies the name of the HTML cropper element
         */
        name: {
            type: String,
            required: true,
        },

        /**
         * Maximum zoom range and zoomBar color
         */
        maxZoom: {
            type: Number,
            default: 2,
        },
        zoomBarColor: {
            type: String,
            default: "grey",
        },

        /**
         * Text of the decline button
         */
        declineButtonText: {
            type: String,
            required: false,
        },
        /**
         * Text of the submit button
         */
        submitButtonText: {
            type: String,
            required: false,
        },
        /**
         * image file which is going to be cropped
         */
        file: {
            type: String,
            required: true,
        },
    },
    /**
     * setup to save saving states while uploading
     */
    setup(props, ctx) {
        const imageURL = ref<string>("");
        const zoomValue = ref<number>(1);
        const cropImageElement = ref();
        const cropImageBoxElement = ref<HTMLElement>();
        /**
         * Anmiation of default button
         */
        const animationStyle = ref<CustomButtonStyleInterface>({});

        /**
         * init cropper and create canvas on img component
         */
        let cropper: Cropper;
        setTimeout(() => {
            let cropImage: HTMLCanvasElement;
            cropImage = cropImageElement.value;
            cropper = new Cropper(cropImage, {
                aspectRatio: 1,
                minCropBoxWidth: cropImageBoxElement.value?.clientWidth,
                minCropBoxHeight: cropImageBoxElement.value?.clientHeight,
                viewMode: 0,
                dragMode: "move",
                scalable: true,
                zoomable: false,
                rotatable: true,
                cropBoxMovable: false,
                cropBoxResizable: false,
            });
        }, 150);
        /**
         * function to crop the image and emit it back to parent element
         */
        function saveImage(): void {
            const imageURLCropped: string = cropper!
                .getCroppedCanvas({
                    width: cropImageBoxElement.value?.clientHeight,
                    height: cropImageBoxElement.value?.clientHeight,
                    imageSmoothingQuality: "high",
                })
                .toDataURL("image/png");
            ctx.emit("saveImage", {
                imageURL: imageURL.value,
                croppedImage: imageURLCropped,
            });
        }
        /**
         * Function for zoom bar
         */
        function zoom() {
            cropper!.scaleX(zoomValue.value);
            cropper!.scaleY(zoomValue.value);
        }
        /**
         * declinebutton function to emit a remove call to parent
         */
        function removeImage() {
            ctx.emit("removeImage");
        }

        return {
            saveImage,
            removeImage,
            zoom,
            animationStyle,
            imageURL,
            zoomValue,
            cropImageElement,
            cropImageBoxElement,
        };
    },
});
