
import { computed, defineComponent, reactive, ref } from "vue";
import InputDefault from "../../../../components/inputs/inputDefault/InputDefault.vue";
import InputSwitch from "../../../../components/inputs/inputSwitch/InputSwitch.vue";
import CustomButton from "../../../../components/inputs/customButton/CustomButton.vue";
import AvatarContainer from "../../../../components/helpers/avatarContainer/AvatarContainer.vue";
import ImageUploadModal from "./ImageUploadModal.vue";
import ChangeEmailModal from "./SettingsChangeEmailModal.vue";
import ChangePasswordModal from "./SettingsChangePasswordModal.vue";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/store/auth";
import { AuthUserDto } from "@/store/auth/interfaces";
import { useHelpersStore } from "@/store/helpers";
import { ToastMessageType } from "@/components/helpers/theToastMessage/toast_message_helpers";
import { convertBase64ToBlob } from "@/common/convert_b64";

export default defineComponent({
    name: "settingsProfileAndSecurity",
    components: {
        InputDefault,
        InputSwitch,
        CustomButton,
        AvatarContainer,
        ImageUploadModal,
        ChangeEmailModal,
        ChangePasswordModal,
    },
    setup() {
        const modelValue = ref<string>("");
        const hasTwoFactor = ref<boolean>(false);
        const openImageUpload = ref<boolean>(false);
        const profileImage = ref<any>(null);
        const changeEmail = ref<boolean>(false);
        const changePassword = ref<boolean>(false);
        const state = reactive({});

        const { user } = storeToRefs(useAuthStore());
        const { toastMessageText } = storeToRefs(useHelpersStore());
        const { updateUser, updateUserAvatar } = useAuthStore();
        const { setLoadingId, removeLoadingId } = useHelpersStore();

        async function saveImage(image: any): Promise<void> {
            profileImage.value = image;
            let fd = new FormData();
            fd.append("image", convertBase64ToBlob(image), "image.png");
            if (user.value) user.value.avatar_url = null;
            setLoadingId("save-upload-button");
            await updateUserAvatar(fd);
            removeLoadingId("save-upload-button");

            openImageUpload.value = false;
        }
        async function updateUserData(data: string): Promise<void> {
            let sendData = { id: user.value?.id } as AuthUserDto;
            if (data == "first_name") {
                sendData.first_name = user.value?.first_name;
            } else if (data == "last_name") {
                sendData.last_name = user.value?.last_name;
            } else if (data == "twoFactor") {
                sendData.has_two_factor_enabled = user.value
                    ?.has_two_factor_enabled
                    ? user.value?.has_two_factor_enabled
                    : false;
            }
            const response = await updateUser(sendData);
            if (response?.status == 200) {
                toastMessageText.value = [
                    {
                        type: "success" as ToastMessageType,
                        message: "Profil wurde erfolgreich aktualisiert.",
                    },
                ];
            } else {
                toastMessageText.value = [
                    {
                        type: "error" as ToastMessageType,
                        message: "Profil konnte nicht aktualisert werden.",
                    },
                ];
            }
        }

        const displayName = computed(() => {
            let name = "Lauer Normenmanager";
            if (user.value) {
                if (user.value.customer) {
                    name = user.value.customer.name ?? "-";
                } else {
                    name = user.value.full_name ?? "-";
                }
            }
            return name;
        });
        return {
            state,
            saveImage,
            modelValue,
            hasTwoFactor,
            openImageUpload,
            profileImage,
            changeEmail,
            changePassword,
            user,
            updateUser,
            updateUserData,
            displayName,
        };
    },
});
