
import { computed, defineComponent, reactive, ref } from "vue";
import CenteredModal from "../../../../components/layout/modals/CenteredModal.vue";
import CustomButton from "../../../../components/inputs/customButton/CustomButton.vue";
import InputDefault from "../../../../components/inputs/inputDefault/InputDefault.vue";
import { useAuthStore } from "@/store/auth";
import { useHelpersStore } from "@/store/helpers";
import { storeToRefs } from "pinia";
import { ToastMessageType } from "@/components/helpers/theToastMessage/toast_message_helpers";

export default defineComponent({
    name: "settingsChangePasswordModal",
    components: { CenteredModal, CustomButton, InputDefault },
    props: {
        open: {
            type: Boolean,
            required: true,
        },
    },
    emits: ["close", "saveImage"],
    setup(props, ctx) {
        const { updateUser } = useAuthStore();
        const { setLoadingId, removeLoadingId } = useHelpersStore();
        const { toastMessageText } = storeToRefs(useHelpersStore());
        const oldPassword = ref<string>("");
        const newPassword = ref<string>("");
        const repeatNewPassword = ref<string>("");
        const state = reactive({});
        const isAllFilled = computed(() => {
            let isFilled = false;
            if (
                oldPassword.value &&
                newPassword.value &&
                repeatNewPassword.value
            ) {
                isFilled = true;
            }
            return isFilled;
        });
        async function updatePassword(): Promise<void> {
            setLoadingId("save-button");
            const response = await updateUser({
                current_password: oldPassword.value,
                password: newPassword.value,
                password_confirmation: repeatNewPassword.value,
            });
            removeLoadingId("save-button");
            if (response?.status == 200) {
                toastMessageText.value = [
                    {
                        type: "success" as ToastMessageType,
                        message: "Passwort wurde erfolgreich aktualisiert.",
                    },
                ];
                ctx.emit("close");
            }
        }
        return {
            state,
            isAllFilled,
            oldPassword,
            newPassword,
            repeatNewPassword,
            updatePassword,
        };
    },
});
