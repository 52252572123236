import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex items-center justify-center" }
const _hoisted_2 = ["id"]
const _hoisted_3 = ["accept"]
const _hoisted_4 = ["accept"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_cloud_upload = _resolveComponent("icon-cloud-upload")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      id: _ctx.id,
      class: _normalizeClass(["rounded-full border-dashed justify-center items-center flex transition transform cursor-pointer", `border-2 border-${_ctx.dragCounter != 0
                ? _ctx.finalFileUploadStyle.hoverBorderColor
                : _ctx.finalFileUploadStyle.borderColor
            } w-${_ctx.dragCounter != 0
                ? _ctx.finalFileUploadStyle.hoverWidth
                : _ctx.finalFileUploadStyle.width
            } h-${_ctx.dragCounter != 0
                ? _ctx.finalFileUploadStyle.hoverHeight
                : _ctx.finalFileUploadStyle.height
            }`]),
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$refs.fileInput.click()))
    }, [
      (!_ctx.hasMultiple)
        ? (_openBlock(), _createElementBlock("input", {
            key: 0,
            id: "file",
            type: "file",
            ref: "fileInput",
            accept: _ctx.acceptedTypes,
            class: "hidden",
            onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.inputChangeEvent && _ctx.inputChangeEvent(...args)))
          }, null, 40, _hoisted_3))
        : (_openBlock(), _createElementBlock("input", {
            key: 1,
            id: "file",
            type: "file",
            ref: "fileInput",
            accept: _ctx.acceptedTypes,
            class: "hidden",
            multiple: "",
            onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.inputChangeEvent && _ctx.inputChangeEvent(...args)))
          }, null, 40, _hoisted_4)),
      _createVNode(_component_icon_cloud_upload, {
        color: `${_ctx.dragCounter != 0
                    ? _ctx.finalFileUploadStyle.hoverIconColor
                    : _ctx.finalFileUploadStyle.iconColor
                }`,
        class: "transform scale-150"
      }, null, 8, ["color"])
    ], 10, _hoisted_2)
  ]))
}