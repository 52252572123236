
import IconCloudUpload from "@/components/icons/IconCloudUpload.vue";
import {
    computed,
    defineComponent,
    onMounted,
    watch,
    ref,
    ComputedRef,
} from "vue";
import { FileUploadStyleInterface } from "./file_upload_interface";
import { fileUploadDefaultStyle } from "./file_upload_default_style";
import { getSpaceConfigStyle } from "@/components/space_config_style";

export default defineComponent({
    name: "FileUpload",
    components: { IconCloudUpload },
    props: {
        /**
         * id is necessary to uniquely identify this spinner
         */
        id: {
            type: String,
            required: true,
        },
        /**
         * specifies the name of the HTML spinner element
         */
        name: {
            type: String,
            required: true,
        },
        /**
         * Size interface of the loadingSpinner, setting strokeWidth, strokeLength and radius of the spinner
         */
        fileUploadStyle: {
            type: Object as () => FileUploadStyleInterface,
            default: {} as FileUploadStyleInterface,
        },
        /**
         * dragId is necessary to uniquely identify the element which reacts to the drag/drop event
         */
        dragId: {
            type: String,
            required: true,
        },
        /**
         * enables the option to upload more than one file
         */
        hasMultiple: {
            type: Boolean,
            default: false,
        },
        /**
         * enables the option to upload more than one file
         */
        acceptedTypes: {
            type: String,
            default: "*",
        },
    },
    /**
     * setup to save saving states while uploading
     */
    setup(props, ctx) {
        const finalFileUploadStyle: ComputedRef<FileUploadStyleInterface> =
            computed(() => {
                return {
                    ...fileUploadDefaultStyle,
                    ...getSpaceConfigStyle()?.fileUpload,
                    ...props.fileUploadStyle,
                };
            });

        /**
         * save uploaded file inside formadata to send it back to receiving component + simulated upload
         */
        function encodeImageFileAsFormData(dragFile: FileList | null): void {
            var formdata = new FormData();
            if (dragFile) {
                for (let index = 0; index < dragFile.length; index++) {
                    formdata.append("file", dragFile[index]);
                }
            }
            ctx.emit("update:modelValue", dragFile);
        }
        /**
         * event called when input is changed  (only manual input by clicking NOT the drag and drop function)
         */
        function inputChangeEvent(event: Event): void {
            const target = event.target as HTMLInputElement;
            const files: FileList | null = target.files;
            encodeImageFileAsFormData(files);
        }

        //Drag File events
        function dragenter(event: DragEvent): void {
            event.preventDefault();
            dragCounter.value++;
            dragging.value = true;
        }
        function dragleave(event: DragEvent): void {
            event.preventDefault();
            dragCounter.value--;
            dragging.value = false;
        }
        /**
         * Event called when file is dropped in the registered element saving the dropped file/files
         */
        function drop(event: DragEvent) {
            event.preventDefault();
            let droppedFiles = event.dataTransfer?.files;
            if (!droppedFiles) return;
            encodeImageFileAsFormData(droppedFiles);
            dragging.value = false;
            dragCounter.value = 0;
        }
        function dragover(event: DragEvent): void {
            event.preventDefault();
        }
        function removeDragEvent(el: HTMLElement | null) {
            el?.removeEventListener("dragenter", dragenter);
            el?.removeEventListener("dragleave", dragleave);
            el?.removeEventListener("dragover", dragover);
            el?.removeEventListener("drop", drop);
        }
        function addDragEvent(el: HTMLElement | null) {
            el?.addEventListener("dragenter", dragenter);
            el?.addEventListener("dragleave", dragleave);
            el?.addEventListener("dragover", dragover);
            el?.addEventListener("drop", drop);
        }
        /**
         * watcher to dynamically change the receiving element for the drag/drop events
         */
        watch(
            () => props.dragId,
            (newVal, oldVal) => {
                const oldEl = document.getElementById(oldVal);
                removeDragEvent(oldEl);
                const newEl = document.getElementById(newVal);
                addDragEvent(newEl);
            }
        );

        const dragCounter = ref<number>(0);
        const dragging = ref<boolean>(false);
        /**
         * add eventListener to initial element
         */
        onMounted(() => {
            const element = document.getElementById(props.dragId);
            addDragEvent(element);
        });

        return {
            inputChangeEvent,
            dragCounter,
            dragging,
            finalFileUploadStyle,
        };
    },
});
